import { useCallback, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { App, Job } from '@typings';
import { PATH } from '@constants';
import { getRunningJobs, getStorageApps } from '@services';
import {
  clusterContextSelector,
  contextNamesSelector,
  userSelector,
} from '@selectors';
import { useSelector } from '@hooks';
import { as, toastifyResponseError } from '@utils';

import * as Page from '@pages';

/**
 * todo: split fetching into `useApps` hook
 */
export const AppsRoutes = () => {
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);
  const cluster = useSelector(clusterContextSelector);
  const { username } = useSelector(userSelector);

  /**
   * Installed dedicated app names
   */
  const [installedDedicatedApps, setInstalledDedicatedApps] = useState<
    App.DedicatedModel[]
  >([]);
  const [runningJobs, setRunningJob] = useState<Job.Running[]>([]);
  const [title, setTitle] = useState('');

  const { storageUrl } = as.c(cluster);

  const getDedicatedApps = useCallback(async () => {
    if (!projectName) {
      return;
    }

    try {
      const dedicatedApps = await getStorageApps({
        organizationName,
        projectName,
        storageUrl,
      });

      setInstalledDedicatedApps(dedicatedApps);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ error });
      /**
       * Continue regardless error
       */
    }
  }, [organizationName, projectName, storageUrl]);

  const getJobs = useCallback(async () => {
    if (!clusterName || !projectName) {
      return;
    }

    try {
      const { jobs } = await getRunningJobs({
        clusterName,
        organizationName,
        projectName,
        username,
        isJustCurrentUser: true,
      });

      const runningJobs = jobs.map((job) => {
        const DELIMITER = 'target:';
        const { name, tags = [] } = job;
        const tag = tags.find((tag) => tag.includes(DELIMITER)) ?? '';
        const [, targetName = name] = tag.split(DELIMITER);

        return {
          ...job,
          targetName,
        };
      });

      setRunningJob(runningJobs);
    } catch (error) {
      toastifyResponseError(error);
    }
  }, [clusterName, organizationName, projectName, username]);

  useEffect(() => {
    const INTERVAL_TIMEOUT = 15_000;

    getJobs();
    getDedicatedApps();

    const intervalId = setInterval(() => {
      getJobs();
      getDedicatedApps();
    }, INTERVAL_TIMEOUT);

    return () => {
      clearInterval(intervalId);
    };
  }, [getJobs, getDedicatedApps]);

  return (
    <Routes>
      <Route element={<Page.Apps title={title} />}>
        <Route
          index
          element={
            <Page.AppsOutlet
              runningJobs={runningJobs}
              installedDedicatedApps={installedDedicatedApps}
              setTitle={setTitle}
            />
          }
        />
        <Route
          path="/"
          element={
            <Page.AppsOutlet
              runningJobs={runningJobs}
              installedDedicatedApps={installedDedicatedApps}
              setTitle={setTitle}
            />
          }
        />
        <Route
          path="installed/:appName?"
          element={
            <Page.AppsInstalled
              runningJobs={runningJobs}
              installedDedicatedApps={installedDedicatedApps}
              getJobs={getJobs}
              setTitle={setTitle}
            />
          }
        />
        <Route path="*" element={<Navigate replace to={PATH.APPS} />} />
      </Route>
    </Routes>
  );
};
